import Vue from "vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import VueFormWizard from "vue-form-wizard";
Vue.use(VueFormWizard);

import TurbolinksAdapter from "vue-turbolinks";
Vue.use(TurbolinksAdapter);

import VeeValidate from "vee-validate";
Vue.use(VeeValidate, {
  classes: true,
  fieldsBagName: "formFields",
  classNames: {
    valid: "is-valid",
    invalid: "is-invalid"
  }
});

import "src/libs/axios.js";

import Form from "src/admin/components/quizzes/form.vue";

import QuizStore from "../stores/_quiz";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    QuizStore
  }
});

document.addEventListener("turbolinks:load", () => {
  let el = document.getElementById("admin-quiz-form");
  if (!!el) {
    new Vue({
      store,
      el: el,
      template: "<Form/>",
      components: { Form }
    });
  }
});
