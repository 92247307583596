<template lang="pug">
  #editor
    textarea(:value="input", :name="name", @input="update", placeholder="Write some markdown here")
    div(v-html="highlightedMarkup")
</template>

<script>
import debounce from "lodash/debounce";
import marked, { Renderer } from "marked";
import highlightjs from "highlight.js";
import "highlight.js/styles/darkula.css";

function setMarkedRenderer() {
  const renderer = new Renderer();
  renderer.code = (code, language) => {
    const validLang = !!(language && highlightjs.getLanguage(language));
    let extLang = highlightjs.getLanguage(language);
    const highlighted = validLang
      ? highlightjs.highlight(language, code).value
      : code;
    return `<pre><code class="hljs ${language}">${highlighted}</code></pre>`;
  };
  marked.setOptions({ renderer });
}

export default {
  props: ["value", "name"],

  mounted() {
    setMarkedRenderer();
  },

  computed: {
    compiledMarkdown: function() {
      return marked(this.input, { sanitize: true });
    },

    highlightedMarkup: function() {
      let markup = this.compiledMarkdown.replace(
        new RegExp(/\?\?\?/, "g"),
        match => {
          return '<span class="highlightText">|__________|</span>';
        }
      );
      return markup.replace(/\r|\n/, "<br>");
    },

    input() {
      return this.value;
    }
  },

  methods: {
    update: debounce(function(e) {
      this.$emit("input", e.target.value);
    }, 300)
  }
};
</script>

<style lang="scss">
@import "../../styles/markdown_editor.sass";
</style>
