import axios from "axios";
import _ from "lodash";

export class Question {
  constructor(id) {
    this.title = "";
    this.body = "";
    this.number_of_slots = 0;
    this.answers_attributes = [
      {
        body: "",
        correct: false
      },
      {
        body: "",
        correct: false
      }
    ];
  }
}

const QuizStore = {
  namespaced: true,

  state: {
    quiz: {
      name: "",
      body: "Hello There! \ Thank you for getting on board!",
      questions_attributes: [new Question(1)],
      role: null,
    },
    errors: {}
  },

  mutations: {
    one(state, data) {
      state.errors = {};
      state.quiz = data;
      return state;
    }
  },

  actions: {
    show(context, id) {
      axios.get(`/admin/quizzes/${id}.json`).then(resp => {
        context.commit("one", resp.data);
      });
    },

    create(_, quiz) {
      return axios
        .post("/admin/quizzes", { quiz: quiz })
        .then(_ => {
          window.location = "/admin/quizzes";
        })
        .catch(error => {
          console.log(error);
        });
    },

    update(context, quiz) {
      return axios
        .patch(`/admin/quizzes/${quiz.id}`, { quiz: quiz })
        .then(response => {
          window.location = "/admin/quizzes";
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};

export default QuizStore;
