<template lang="pug">
form
  .row
    .col-12
      .card.col-12.p-0.mb-4
        .card-header.text-center.bg-accent.text-white(href="javascript:;" data-toggle='collapse', data-target= '#info-section', aria-expanded='true', aria-controls ='info-section')
          span Section 1 - Basic Infos 
          span#info-arrow.fa.fa-lg.fa-angle-down
        #info-section.card-body.collapse.show
          .form-group
            label Name
            input.form-control(
              type="text",
              v-model="form.name",
              placeholder="This will be transformed in a friendly id in the url",
              v-validate="'required'"
            )
            .form-group.mt-2
              label Role
              input.form-control(
                type="text",
                v-model="form.role",
                placeholder="eg: iOS developer, Android developer etc",
                v-validate="'required'"
              )
            .form-group
              label Intro body
              md-editor(v-model="form.body", name="body")
  .row
    .col-12
      .card.col-12.p-0.mb-4
        .card-header.text-center.bg-accent.text-white(href="javascript:;" data-toggle='collapse', data-target= '#question-section', aria-expanded='true', aria-controls ='question-section')
          span Section 2 - Quesions 
          span#question-arrow.fa.fa-lg.fa-angle-down
        #question-section.card-body.collapse.show
          h3 Questions
          button.btn.btn-success(@click.prevent="addQuestion")
            i.fa.fa-plus
            | Add question
          form-wizard(
            title="",
            subtitle="",
            color="#000",
            @on-complete="saveQuiz",
            ref="wizard"
          )
            tab-content(
              v-if="!q._destroy",
              v-for="(q, index) in form.questions_attributes",
              :key="index"
            )
              component(
                is="question",
                :value="q",
                :index="index",
                @change="updateQuestion",
                @remove="removeQuestion"
              )
            wizard-button(
              @click.native="saveQuiz",
              slot="custom-buttons-right",
              slot-scope="props",
              :style="props.fillButtonStyle"
            ) Save & Exit
</template>
<script>
import QuestionComp from "./_question";
import MdEditor from "../shared/markdown_editor";
import { mapState } from "vuex";
import { Question } from "../../stores/_quiz.js";
import { version } from "element-ui";

export default {
  components: {
    MdEditor,
    question: QuestionComp,
  },

  data() {
    return {
      index: 1,
      quizId: null,
    };
  },

  mounted() {
    this.fetchQuiz();
  },

  updated() {
    this.$refs.wizard.activateAll();
  },

  computed: {
    ...mapState({
      form: (state) => state.QuizStore.quiz,
    }),
  },

  methods: {
    addQuestion() {
      this.index += 1;
      this.form.questions_attributes.push(new Question(this.index));

      this.$refs.wizard.activateTabAndCheckStep(0);
    },

    removeQuestion(index) {
      this.index -= 1;
      this.$set(this.form.questions_attributes[index], "_destroy", true);

      if (
        this.$refs.wizard.activeTabIndex < 1 &&
        this.$refs.wizard.tabCount > 1
      ) {
        this.$refs.wizard.nextTab();
      }
    },

    saveQuiz() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.quizId) {
            this.$store.dispatch("QuizStore/create", this.form);
          } else {
            this.$store.dispatch("QuizStore/update", this.form);
          }
        }
      });
    },

    updateQuestion(payload) {
      this.form.questions_attributes[payload["index"]] = payload["value"];
    },

    fetchQuiz() {
      let quizId = document.getElementById("quiz-id");
      if (!!quizId) {
        this.quizId = quizId.dataset["id"];
        this.$store.dispatch("QuizStore/show", this.quizId);
      }
    },
  },
};
</script>
