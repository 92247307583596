<template lang="pug">
  .row.mt-2
    .col-12
      div.btn.btn-success(@click.prevent="addAnswer")
        i.fa.fa-plus
        | Add answer
    .col-6.mt-2
      label Check and reorder the correct answers
      br
      label(v-if="numberOfGaps > 0") This is a fill the gap question. The first {{numberOfGaps}} answers will be the correct answers (order of answers should correspond to order of gaps in the question).
      draggable.list-group.draggable-area(element='ul',
                                  :list='answers',
                                  :options='dragOptions',
                                  @start='isDragging=true'
                                  )
        li.list-group-item.clearfix(v-if="!element._destroy", v-for='(element, index) in answers', :key='index')
          span.pull-left
            input.form-control(type="text", v-model="element.body")
          span.pull-right
            button.btn.btn-danger.mr-2(type="button", @click.prevent="removeAnswer(element)")
              i.fa.fa-minus
              |  Remove
            label
              input(type="checkbox", v-model="element.correct", :disabled="numberOfGaps > 0")
              |  Correct
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: ["value", "name", "numberOfGaps"],

  components: {
    draggable
  },

  data() {
    return {
      lastIndex: this.value.length + 1
    };
  },

  updated() {
    if (this.numberOfGaps > 0) {
      this.markCorrectAnswers();
    }
  },

  methods: {
    addAnswer() {
      let newIndex = this.lastIndex++;
      this.answers.push({
        body: "",
        correct: false
      });
    },

    removeAnswer(answer) {
      this.$set(answer, "_destroy", true);
    },

    markCorrectAnswers() {
      while (this.answers.length < this.numberOfGaps) {
        this.addAnswer();
      }

      this.answers.slice(0, this.numberOfGaps).forEach((answer, index) => {
        answer.correct = true;
        answer.order = index;
      });

      this.answers.slice(this.numberOfGaps, this.answers.length).forEach((answer, index) => {
        answer.correct = false;
        answer.order = index + this.numberOfGaps;
      });
    }
  },

  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        ghostClass: "ghost"
      };
    },

    answers() {
      return this.value
    }
  }
};
</script>

<style lang="scss">
@import '../../styles/answer_editor.sass';
</style>