<template lang="pug">
  div
    .form-group
      button.btn.btn-danger(@click.prevent="removeQuestion")
        | Remove question
    .form-group
      label Title
      input.form-control(v-model="value.title", name="title")
    .form-group
      label Body
      p Hint: type ??? to insert gap for answer (not mandatory)
      md-editor(v-model="value.body", name="body")
    .form-group
      label Answers
      answers-editor(:value="value.answers_attributes", name="answers", :numberOfGaps="value.number_of_slots")
</template>

<script>
  import MdEditor from "../shared/markdown_editor";
  import AnswersEditor from "./_answers_editor";

  export default {
    props: ["value", "index"],

    components: {
      MdEditor,
      AnswersEditor
    },

    updated() {
      this.value.number_of_slots = (this.value.body.match(/\?\?\?/g) || []).length;
    },

    methods: {
      removeQuestion() {
        this.$emit("remove", this.index);
      }
    }
  };
</script>
